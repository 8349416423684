import React from 'react';
import ipad from '../image/ipad.png'
export class About extends React.Component {
    render() {

        return  <section className="about-section text-center" id="about">
        <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-8">
                    <h2 className="text-white mb-4"></h2>
                    <p className="text-white-50">
                    “Re: Tower Medinfo” is a company founded by 4 people specializing in Healthcare Information System for clinics in Taiwan. Our primary mission is to revolutionize the HIS landscape by addressing the current pain points and enhancing the overall efficiency of medical practices.
                    </p>
                </div>
            </div>
            <img className="img-fluid" src={ipad} alt="..." />
        </div>
    </section>
    };

}
