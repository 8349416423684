import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {Navigation} from './Navigation'
import {Masthead} from './Masthead'
import {Contact} from './Contact'
import {Footer} from './Footer'
import ChatGPT from './ChatGPT';
import { About } from './About';

const Dashboard = () => {
  const location = useLocation();
  const arrayParam = location.state?.arrayParam || [];

  return (
    <div className="App">
      <Navigation/>
      <Masthead />
      <ChatGPT arrayParam={arrayParam} />
      <Contact />
      <Footer />
    </div>
  );
};

export default Dashboard;
