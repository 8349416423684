import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const { setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .min(3, ''),
            password: Yup.string()
                .min(6, ''),
        }),
        onSubmit: async values => {
            console.log("登入")
            const { username, password } = values;
            const data = new FormData();
            data.append('username', username);
            data.append('password', password);
    

            try {
                const response = await fetch('https://marcoliu.ddns.net:1234/login', {
                    method: 'POST',
                    body: data,
                  });

                if (!response.ok) {
                    console.log(response)
                    throw new Error(`Request failed with status code ${response.status}`);
                }

                const responseData = await response.json();
                setIsAuthenticated(true);
                navigate('/dashboard', { state: { arrayParam: responseData.user } });
            } catch (error) {
                console.error('登入失敗：', error);
                // 處理註冊失敗，例如顯示錯誤信息
            }
        },
    });

    return (
        <div className="login-container">
            <div className="form-container">
                <h1>登入</h1>
                <form onSubmit={formik.handleSubmit}>
                    <label htmlFor="email">帳號：</label>
                    <input
                        id="username"
                        name="username"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.username}
                    />
                    {formik.errors.email ? <div>{formik.errors.email}</div> : null}

                    <label htmlFor="password"> 密碼：</label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                    {formik.errors.password ? <div>{formik.errors.password}</div> : null}

                    <button type="submit">登入</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
