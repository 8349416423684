import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Register.css';
import { useNavigate } from 'react-router-dom';
const Register = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            hospitalName: '',
            confirmPassword: '',
            mainDoctorName: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().min(2, '用戶名至少需要2個字符'),
            hospitalName: Yup.string().min(2, '診所名稱至少需要2個字符'),
            mainDoctorName: Yup.string().min(2, '醫生名至少需要2個字符'),
            password: Yup.string().min(6, '密碼至少需要6個字符'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], '密碼不一致')
        }),
        onSubmit: async values => {
            const { username, password, confirmPassword, hospitalName, mainDoctorName } = values;
            const data = new FormData();
            data.append('username', username);
            data.append('password', password);
            data.append('confirmPassword', confirmPassword);
            data.append('hospitalName', hospitalName);
            data.append('mainDoctorName', mainDoctorName);

            try {
                const response = await fetch('https://marcoliu.ddns.net:1234/register', {
                    method: 'POST',
                    body: data,
                });

                if (!response.ok) {
                    throw new Error(`Request failed with status code ${response.status}`);
                }

                const responseData = await response.json();
                console.log('註冊成功：', responseData);
                navigate(`/login`);
            } catch (error) {
                console.error('註冊失敗：', error);
                // 處理註冊失敗，例如顯示錯誤信息
            }
        }
    });

    return (
        <div className="register-container">
            <div className="form-container">
                <h1>註冊</h1>
                <h3>紅樓醫療資訊/re-tower.com - 網路註冊和資料收集知情同意書</h3>
                <p>
                    介紹：<br />
                    本知情同意書概述了在我們的網站上註冊的條款和條件。完成註冊流程即表示您同意以下條款。在進行註冊之前，請仔細閱讀以下資訊。

                    資料收集和使用：
                    通過在我們的網站上註冊，您同意提供您的電子郵件地址、您的姓名和您的診所名稱。這些資訊將用於以下目的：
                    a. 在我們的網站上創建並管理您的用戶帳戶。
                    b. 向您發送與我們的產品和服務相關的廣告和促銷資料。
                    我們向您保證，除了上述提到的資訊之外，我們不會收集您或您在網頁上的任何其他資訊，包括您在網站上輸入的任何數據。
                    資料保護：

                    我們重視您的資料的安全和隱私。我們使用行業標準的安全措施來保護您的資訊免受未經授權的訪問、披露、更改或破壞。
                    未經您的同意，我們不會將您的個人身份資訊出售、交易或以其他方式轉讓給外部方，除非法律要求或為上述目的所需。

                    您的權利：
                    您有權隨時通過適當渠道查閱、更新或刪除您的個人資訊。
                    您還可以隨時選擇取消接收我們的促銷電子郵件或廣告，方法是點擊我們發送給您的任何電子郵件中的「取消訂閱」鏈接，或直接與我們聯繫。

                    同意：
                    通過在我們的網站上完成註冊流程，您特此確認您已閱讀並理解本知情同意書，並同意其條款。您還同意為上述目的收集和使用您的電子郵件地址、您的姓名和您的診所名稱。

                    如果您對本知情同意書或我們的資料收集做法有任何疑問或疑慮，請寄電子郵件至 <span className="highlighted-text">marco@re-tower.com</span>告知我們，感謝您。
                </p>
                <br></br>
                <p>Re: Tower Medinfo / re-tower.com - Informed Consent for Web Registration and Data Collection

                    Introduction:
                    This Informed Consent document outlines the terms and conditions for registering on our website. By completing the registration process, you acknowledge and agree to the terms listed below. Please read the following information carefully before proceeding with registration.

                    Data Collection and Use:
                    By registering on our website, you agree to provide your email address, your name, and your clinic name. This information will be used for the following purposes:
                    a. To create and manage your user account on our website.
                    b. To send you relevant advertisements and promotional materials related to our products and services.
                    We assure you that we will not collect any other information from you or your web browsing activities, including any data you type in on our website, beyond the information mentioned above.
                    Data Protection:

                    We take the security and privacy of your data seriously. We use industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.
                    We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties without your consent, except as required by law or as necessary for the purposes stated above.

                    Your Rights:
                    You have the right to access, update, or delete your personal information at any time by contacting us through the appropriate channels.
                    You may also opt-out of receiving promotional emails or advertisements from us at any time by clicking the "unsubscribe" link in any email we send you or by contacting us directly.
                    Consent:

                    By completing the registration process on our website, you hereby acknowledge that you have read and understood this Informed Consent document and agree to its terms. You also give your consent to the collection and use of your email address, your name, and your clinic name for the purposes described above.

                    If you have any questions or concerns regarding this Informed Consent or our data collection practices, please send email to <span className="highlighted-text">marco@re-tower.com</span>.</p>
                <br></br>
                <br></br>
                <br></br>

                <form class="STYLE-NAME" onSubmit={formik.handleSubmit}>
                    <div>
                        <label htmlFor="username">用戶名</label><br />
                        <input
                            id="username"
                            name="username"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                        />
                    </div>
                    {formik.errors.username ? <div>{formik.errors.username}</div> : null}

                    <div>
                        <label htmlFor="hospitalName">診所名稱</label><br />
                        <input
                            id="hospitalName"
                            name="hospitalName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.hospitalName}
                        />
                    </div>
                    {formik.errors.hospitalName ? <div>{formik.errors.hospitalName}</div> : null}

                    <div>
                        <label htmlFor="mainDoctorName">醫生名</label><br />
                        <input
                            id="mainDoctorName"
                            name="mainDoctorName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.mainDoctorName}
                        />
                    </div>
                    {formik.errors.mainDoctorName ? (
                        <div>{formik.errors.mainDoctorName}</div>
                    ) : null}

                    <div>
                        <label htmlFor="password">密碼</label><br />
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                    </div>
                    {formik.errors.password ? <div>{formik.errors.password}</div> : null}

                    <div>
                        <label htmlFor="confirmPassword">確認密碼</label><br />
                        <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.confirmPassword}
                        />
                    </div>
                    {formik.errors.confirmPassword ? (
                        <div>{formik.errors.confirmPassword}</div>
                    ) : null}

                    <div>
                        <input
                            type="checkbox"
                            id="termsCheckbox"
                            name="termsCheckbox"
                            onChange={handleCheckboxChange}
                            checked={isChecked}
                        />
                        <label htmlFor="termsCheckbox">我已閱讀並同意知情同意書條款</label>
                    </div>
                    {!isChecked}
                    <button
                        type="submit"
                        disabled={!isChecked}
                        className={isChecked ? 'register-button' : 'register-button-disabled'}
                    >
                        註冊
                    </button>
                </form>

            </div>
        </div>
    );
};

export default Register;
