import React, { useState } from 'react';
import '../ChatGPT.css';
import '../Loading.css'


const { Configuration, OpenAIApi } = require('openai');

const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY
});
const handlePrint = () => {
    const printSection = document.getElementById("print-section");
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write(printSection.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
};
delete configuration.baseOptions.headers['User-Agent'];
const openai = new OpenAIApi(configuration);


async function getChatGptResponse(setResponseText, setLoading, value, request) {
    setLoading(true)
    try {
        const response = await openai.createChatCompletion({
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'assistant', content: request }],
            temperature: 1.0,
        });

        console.log(response.data.choices[0].message.content);
        setResponseText(response.data.choices[0].message.content)
        if (value == false) {
            setLoading(false)
        }

    } catch (err) {
        console.log('Error: ' + err);
        setResponseText('Error: ')
        setLoading(value)
    }
}

const inputStyle = {
    display: 'inline-block',
    width: '20%',
    marginBottom: '10px',
};




const ChatGPT = (props) => {
    const { arrayParam } = props;
    const [dialysisTime, setDialysisTime] = useState('4');
    const [ultrafiltrationVolume, setUltrafiltrationVolume] = useState('3');
    const [postDialyticWeight, setPostDialyticWeight] = useState('60');
    const [bunPre, setBunPre] = useState('84');
    const [bunPost, setBunPost] = useState('20');
    const [creatinine, setCreatinine] = useState('14.5');
    const [sodium, setSodium] = useState('143');
    const [potassium, setPotassium] = useState('2.5');
    const [calcium, setCalcium] = useState('13');
    const [phosphorus, setPhosphorus] = useState('8');
    const [hemoglobin, setHemoglobin] = useState('10');
    const [albumin, setAlbumin] = useState('4.3');
    const [intactPTH, setIntactPTH] = useState('2000');
    const [ctRatio, setCTRatio] = useState('70');
    const [responseText, setResponseText] = useState('');
    const [loading, setLoading] = useState('');
    const [spktv, setSpktv] = useState('0.000');
    const [eKtV, setEktV] = useState('0.000');
    const [urr, setUrr] = useState('60');

    const handleSubmit = async () => {
        console.log()
        let R = bunPost / bunPre
        const spktv = -Math.log(R - 0.008 * dialysisTime) + (4 - 3.5 * R) * ultrafiltrationVolume / postDialyticWeight;
        const eKtV = spktv - (0.6 * spktv) / dialysisTime + 0.03;
        let bun = bunPre - bunPost
        const urr = bun / bunPre
        const percentage = (urr * 100).toFixed(0);
        setSpktv(spktv)
        setEktV(eKtV)
        setUrr(percentage)
        console.log("spktv:", spktv)
        console.log("eKtV:", eKtV)
        console.log("urr:", percentage)

        const prompt = "You are now acting as a physician. Here is a patient under regular hemodialysis. And gave out labaratory value: urea removal rate:"+ percentage +"% , spKt/V:"+ spktv +" , eKt/V: "+ eKtV +", serum albumin: "+ albumin +", serum sodium: "+ sodium +", serum potassium:"+ potassium +", serum calcium: "+ calcium +", serum phosphate: "+ phosphorus +". serum hemoglobin: "+ hemoglobin +",  Intact PTH: "+ intactPTH +", Chest X ray gave out cardiothoracic ratio as "+ ctRatio +"%. Please use traditional chinese to list the reference range suitable for dialysis patient and also their data, while give them advice to improve these data respectively under every entry at the same time. If there is no data input at that entry, then skip it out.請使用繁體中文，並且給我HTML格式"
        console.log(prompt)
        await getChatGptResponse(setResponseText, setLoading, false, prompt);

    };

    return (
        <div id="print-section" >


            {loading ? (
                <div className="loader"></div>
            ) : (
                <div>
                </div>
            )}
            <div className="array-items-container">
                <p>醫生: {arrayParam.username}</p>
                <p>診所: {arrayParam.hospitalname}</p>

            </div>
            <div style={inputStyle}>
                <label>
                    Dialysis time:
                    <input
                        type="text"
                        value={dialysisTime}
                        onChange={(e) => setDialysisTime((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    Ultrafiltration volume:
                    <input
                        type="text"
                        value={ultrafiltrationVolume}
                        onChange={(e) => setUltrafiltrationVolume((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    Post-dialytic weight:
                    <input
                        type="text"
                        value={postDialyticWeight}
                        onChange={(e) => setPostDialyticWeight((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    BUN (pre-dialytic):
                    <input
                        type="text"
                        value={bunPre}
                        onChange={(e) => setBunPre((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    BUN (post-dialytic):
                    <input
                        type="text"
                        value={bunPost}
                        onChange={(e) => setBunPost((e.target.value))}
                    />
                </label>
            </div>
            <br />
            <div style={inputStyle}>
                <label>
                    Creatinine:
                    <input
                        type="text"
                        value={creatinine}
                        onChange={(e) => setCreatinine((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    Sodium:
                    <input
                        type="text"
                        value={sodium}
                        onChange={(e) => setSodium((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    Potassium:
                    <input
                        type="text"
                        value={potassium}
                        onChange={(e) => setPotassium((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    Calcium:
                    <input
                        type="text"
                        value={calcium}
                        onChange={(e) => setCalcium((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    Phosphorus:
                    <input
                        type="text"
                        value={phosphorus}
                        onChange={(e) => setPhosphorus((e.target.value))}
                    />
                </label>
            </div>
            <br />
            <div style={inputStyle}>
                <label>
                    Hemoglobin:
                    <input
                        type="text"
                        value={hemoglobin}
                        onChange={(e) => setHemoglobin((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    Albumin:
                    <input
                        type="text"
                        value={albumin}
                        onChange={(e) => setAlbumin((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    Intact PTH:
                    <input
                        type="text"
                        value={intactPTH}
                        onChange={(e) => setIntactPTH((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <label>
                    CT ratio:
                    <input
                        type="text"
                        value={ctRatio}
                        onChange={(e) => setCTRatio((e.target.value))}
                    />
                </label>
            </div>
            <div style={inputStyle}>
                <button onClick={handleSubmit} style={{ marginTop: '10px' }}>
                    Submit
                </button>

            </div>

            <div className="centered-text">
                <p>spKt/V 值: {spktv}。eKt/V 值: {eKtV}。URR 值: {urr}%</p>
            </div>

            <br />
            <div className="response-container">
                <div dangerouslySetInnerHTML={{ __html: responseText }} />
                <br />
            </div>
            <button onClick={handlePrint}>列印頁面</button>

        </div>
    );
};

export default ChatGPT;