import React from 'react';
import demo_image_01 from '../image/demo-image-01.jpg'
import './Projects.css'
export class Projects extends React.Component {
    render() {

        return <section class="projects-section bg-light" id="projects">
            <div class="container px-4 px-lg-5">

                <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
                    <div class="col-lg-6 d-flex justify-content-center align-items-center"><img class="img-fluid round-image" src={demo_image_01} alt="..." /></div>
                    <div class="col-lg-6">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-left">
                                    <h4 class="text-white">Bellamy</h4>
                                    <p class="mb-0 text-white-50">
                                        As a dedicated primary care physician and nephrologist, bring years of valuable clinical experience and insights to the table. Deeply passionate about leveraging technology to enhance patient care and streamline clinical workflows.</p>
                                    <hr class="d-none d-lg-block mb-0 ms-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gx-0 justify-content-center">
                    <div class="col-lg-6 d-flex justify-content-center align-items-center"><img class="img-fluid round-image" src={demo_image_01} alt="..." /></div>
                    <div class="col-lg-6 order-lg-first">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-right">
                                    <h4 class="text-white">Angus</h4>
                                    <p class="mb-0 text-white-50">
                                        extensive expertise in artificial intelligence (AI) and team development, committed to driving technological innovation and fostering a strong and collaborative environment within organization.</p>
                                    <hr class="d-none d-lg-block mb-0 me-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
                    <div class="col-lg-6 d-flex justify-content-center align-items-center"><img class="img-fluid round-image" src={demo_image_01} alt="..." /></div>
                    <div class="col-lg-6">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-left">
                                    <h4 class="text-white">Marco</h4>
                                    <p class="mb-0 text-white-50">
                                        With a solid background in software development and a keen interest in healthcare technology, passionate about using skills to make a meaningful impact on the healthcare sector.</p>
                                    <hr class="d-none d-lg-block mb-0 ms-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gx-0 justify-content-center">
                    <div class="col-lg-6 d-flex justify-content-center align-items-center"><img class="img-fluid round-image" src={demo_image_01} alt="..." /></div>
                    <div class="col-lg-6 order-lg-first">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-right">
                                    <h4 class="text-white">Howard</h4>
                                    <p class="mb-0 text-white-50">
                                        investor and supporter. As a successful entrepreneur with ownership in four other companies, bring extensive experience in business development, financial management, and investment support to the table.</p>
                                    <hr class="d-none d-lg-block mb-0 me-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    };

}
