import React from 'react';
import { Route, Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../components/contexts/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  console.log("isAuthenticated:",isAuthenticated)
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Navigate to="/login" replace />
      }
    />
  );
};

export default PrivateRoute;