import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {Navigation} from './Navigation'
import {Masthead} from './Masthead'
import {Contact} from './Contact'
import {Footer} from './Footer'
import {Projects} from './Projects';
import { About } from './About';

const Dashboard = () => {
  const location = useLocation();
  const arrayParam = location.state?.arrayParam || [];

  return (
    <div className="App">
      <Navigation />
      <Masthead />
      <About/>
      <Projects/>
      <Contact />
      <Footer />
    </div>
  );
};

export default Dashboard;
